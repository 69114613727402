import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
var _excluded = ["id"],
  _excluded2 = ["product"];
import { useStorage } from '@magalu/mixer-utils';
var getCategories = function getCategories(category, viewedProducts, categoryPageId) {
  if (category == null ? void 0 : category.id) {
    return [category.id];
  }
  if (categoryPageId) {
    return [categoryPageId];
  }
  if (viewedProducts == null ? void 0 : viewedProducts.length) {
    return _toConsumableArray(new Set(viewedProducts)).slice(-5);
  }
  return ['MO', 'EP', 'CM', 'UD', 'ME'];
};
var buildQuery = function buildQuery(_ref) {
  var _data$category, _data$category$, _get, _get$items;
  var cookies = _ref.cookies,
    data = _ref.data,
    placements = _ref.placements;
  var _useStorage = useStorage('viewing-history'),
    get = _useStorage.get;
  var _ref2 = (data == null ? void 0 : data.product) || {},
    _ref2$category = _ref2.category,
    category = _ref2$category === void 0 ? {} : _ref2$category;
  var categoryPageId = (data == null ? void 0 : (_data$category = data.category) == null ? void 0 : (_data$category$ = _data$category[0]) == null ? void 0 : _data$category$.id) || null;
  var viewedProducts = ((_get = get()) == null ? void 0 : (_get$items = _get.items) == null ? void 0 : _get$items.map(function (item) {
    return item.category.id;
  })) || [];
  var banners = [];
  var products = [];
  var channelCode;
  placements.forEach(function (_ref3) {
    var name = _ref3.name,
      categories = _ref3.categories,
      limit = _ref3.limit,
      slot = _ref3.slot,
      consumer = _ref3.consumer,
      channel = _ref3.channel;
    if (name === 'AdsShowcase') {
      products.push({
        categories: getCategories(category, viewedProducts, categoryPageId),
        consumer: consumer,
        limit: limit,
        slot: slot
      });
    } else if (name === 'AdsBanner') {
      banners.push({
        categories: categories,
        consumer: consumer,
        limit: limit,
        slot: slot
      });
    }
    if (channel) {
      channelCode = channel;
    }
  });
  var _ref4 = cookies || {},
    partnerId = _ref4.partnerId,
    location = _ref4.location;
  return {
    banners: banners,
    metadata: {
      channelCode: channelCode,
      isLoyalty: false,
      partnerId: partnerId
    },
    products: products,
    zipcode: (location == null ? void 0 : location.zipCode) || ''
  };
};
var buildQueryVariablesFromComponents = function buildQueryVariablesFromComponents(_ref5) {
  var _ref5$components = _ref5.components,
    components = _ref5$components === void 0 ? [] : _ref5$components,
    cookies = _ref5.cookies,
    data = _ref5.data;
  var placements = [];
  var addAdComponent = function addAdComponent(component) {
    if (component.name === 'AdsShowcase' || component.name === 'AdsBanner') {
      var _component$static = component["static"],
        channel = _component$static.channel,
        categories = _component$static.categories,
        consumer = _component$static.consumer,
        limit = _component$static.limit,
        slot = _component$static.slot,
        name = component.name;
      var placement = {
        categories: categories,
        channel: channel,
        consumer: consumer,
        limit: limit,
        name: name,
        slot: slot
      };
      placements.push(placement);
    }
  };
  var processComponent = function processComponent(component) {
    addAdComponent(component);
    if (component == null ? void 0 : component.children) {
      component.children.forEach(function (child) {
        return processComponent(child);
      });
    }
    if (component.type === 'group') {
      component.components.forEach(function (groupComponent) {
        return processComponent(groupComponent);
      });
    }
  };
  if (components.length) {
    components.forEach(function (component) {
      return processComponent(component);
    });
  }
  if (placements.length) {
    return buildQuery({
      cookies: cookies,
      data: data,
      placements: placements
    });
  }
  return null;
};
var parseAdsBanners = function parseAdsBanners(banners) {
  return banners.reduce(function (acc, banner) {
    (acc[banner.slot] = acc[banner.slot] || []).push(banner);
    return acc;
  }, {});
};
var parseProducts = function parseProducts(products) {
  return products.reduce(function (acc, product) {
    var _product$product = product.product,
      id = _product$product.id,
      restProduct = _objectWithoutProperties(_product$product, _excluded),
      rest = _objectWithoutProperties(product, _excluded2);
    var flattenedProduct = _extends({
      id: id
    }, restProduct, rest);
    (acc[product.slot] = acc[product.slot] || []).push(flattenedProduct);
    return acc;
  }, {});
};
export { buildQuery, buildQueryVariablesFromComponents, getCategories, parseAdsBanners, parseProducts };